/*
Template Name: Chatvia - Responsive Bootstrap 5 Chat App
Author: Themesbrand
Version: 2.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/accordion";
@import "custom/components/forms";


// Plugins
@import "custom/plugins/custom-scrollbar";

// structure
@import "custom/structure/sidemenu";

// Pages
@import "custom/pages/chat-leftsidebar";
@import "custom/pages/user-chat";
@import "custom/pages/user-profile-details";
@import "custom/pages/authentication";

body{
    overflow-x: hidden;
}
.text-primary{
    color: green !important;
}
.full-width{
    min-width: calc(100% - 75px);
   background-color:#fff !important;
}
.center{
    text-align: center;
}
.pt-100{
    padding-top:100px;
}
.nav-tabs .nav-link.active{
   background-color: #ffffff; 
   border: none;
 border-bottom: 3px solid #7db756;
}
.nav-tabs .nav-link{
    border: none;
}
.broadcast-image{
    @media (min-width:480px)  { 
        height: 500px;
    }
}
.add-team-image{
    @media (min-width:480px)  { 
        width: 500px;
    }
}
.pt-20{
    padding-top:20px;
}
.pt-50{
    padding-top:50px;
}
.float-right{
    float: right;
}
.rounded-circle-profile{
    background-color: #daeed0  !important;
    padding: 13px;
    border-radius: 50%;
}
.api-setting-image{
    @media (min-width:480px)  { 
        height: 350px;
    }
}
.chip{
    background: #6abb44 !important;
    color: #fff !important;
    // display: none !important;
}
.highlightOption{
    background: #6abb44 !important;
}
.multiSelectContainer li:hover{
    background: #6abb44 !important;
}
.full-width-team{
    width: 100% !important;
}
.searchWrapper{
    border: 1px solid #e9e9e9 !important;
    background-color: #f5f6fa !important;
}
.right{text-align: right;}

.iciELI{
    max-width: 100% !important;
    height: 175px !important;
}

.chat-message-list {
    height: calc(100vh - 100px) !important;
}

.dashboard-card-style{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.dashboard-card-style-graph{
    @media (min-width:768px) {
        min-height: 75vh;
    }
   
}
.number-right{
    float: right;
  color:#535353 ;  
}
.table-cell-align-right{
    float: right; 
}
.apexcharts-toolbar{
    display:none !important;
}
.card-grey-title{
    color:#747272 ;  
    font-size: 11px;
}

.user-chat-container{
    position: relative;
    height: 100%;
    width: 100%;
}

.no-chat-image-container > .no-chat-image{
    position: absolute;
    width: 400px;
    height: 400px;
    left: calc(50% - 200px);
    top: calc(50% - 200px);
}
.no-message-style{
    @media (min-width:480px)  { 
        height: 330px;
    }
}
.pt-30{
    padding-top: 30px;
}
.chat-message-list{
 height: calc(100vh - 145px) !important;
}

.full-width{
    @media (min-width:992px)  { 
        margin-left: 75px;
    }
}

.sidebar-margin{
    @media (min-width:992px)  { 
        margin-left: 75px;
    }
}

.fixed {
    position: fixed;
}

.height-100vh{
min-height: 85vh;
}

.text-grey{
color: #a1a2a2;
}
.pointer{
    cursor: pointer;
}
.full-width-file-upload{
    width: 100%;
}
.kiHXWb{
    max-width: 1170px  !important;
}

.margin-right{
    margin-right: 10px;
}

.radio-button-margin{
    margin: 0 10px 0 10px;
  }

  .chat-filter-btn:focus{
    color: #fff;
    background-color: #6abb44;
    border-color: #6abb44;
  }
  
  .doc-name-style{
      color:#fff;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  .mr-3{
      margin-right: 3px;
  }

  .last-message-width{
      max-width: 220px;
  }
.template-button-margin{
margin-right: 10px;
}
.font-style-italic{
    font-style: italic;
}

.error-font-style {
    text-align: right;
    color: #ff0000;
    font-size: 13px;
}

.preview-card-style{
    justify-content : space-between ;
    display: flex;
}
.role-wid {
    width: 84% ;
  }
  
  .user-role {
    
    @media (min-width: 760px) {
    width:91%  
    }
    @media (max-width: 750px) {
    width:79%  
    }
}

.template-line-height-style {
    line-height: 1!important;
}

.download-report-style {
    color:#6abb44;
    margin-right: 10px;
}

.broadcast-page-style {
    margin-bottom: 40px;
    margin-left: auto;
}
.validation-style{
    padding-left: 7px;
    color:#ff0000;
    font-size:0.9em;
}
.body-variable-background{
    background: #f3f1f1bf !important;
}
.chat-new-line{
    white-space: pre-wrap;
}

.chat-reply-box{
    background: #014f42;
    border-radius: 5px;
}
.chat-list-time{
    font-size:0.7em;
}

.vertical-align-middle{
 vertical-align: middle;
}
.agent-text-size{
    font-size:0.7em;
}
.agent-text-overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7ch;
}

.add-contact-to-block{
    padding-left: 21px;
    padding-right: 21px;
}

.download-campaign-style{
    color: var(--bs-blue);
    float:right;
}

.download-api-report-style{
    color: var(--bs-blue);
    padding-left: 1016px;
}

.setting-icon-style{
    float:right;
    margin-top: 8px;
    color:var(--bs-blue);
    font-size: x-large;
    cursor: pointer;
}
.validation{
    color:#EF476F;
    font-size:0.8em;
}
.eye-icon-style{
    border-color: #e6ebf5;    
}

.login-style{
    font-size: 15px;
    font-weight: 400;
    line-height:22.5px;
    text-transform:capitalize;
    color:#ffffff; 
    background-color: #6ABB44!important;
}

.gold-rate-card-style {
    
    @media (min-width: 760px) {
    width:50%  
    }
    @media (max-width: 750px) {
    width:79%  
    }
}

.bot-style {
    max-width: 27px;
    padding-left: 9px;
}

.bot-thumbnail-style {
    width: 18px;
    max-width: 18px;
    padding-bottom: 4px;
}
.message-box{
    min-height: 100px;  
}
.textarea-lineheight{
    padding: 0;
    max-height: 24px;
    min-height: 24px!important;
    display: inline-block;
    max-width: 50px;
    margin-top: 5px;
}
.textarea-length{
    margin-left: 10px;
    margin-right: 18px;
}
.schedule-time-style{
    width:380px

}

.label-text{
    font-size: 12px;
}

.chatbot-image{
    width: 300px;
}

.react-flow__handle{
    width: 12px !important;
    height: 12px !important;
}

.file-uploader-width{
    width: 30px !important;
}
.center-button{
    margin: 0 auto;
    display: block;
}

.mr-20{
    margin-right: 20px;
    }